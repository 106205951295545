import React, { useState } from 'react';
import Image from '../../../components/images/Image';
import background from '../../../assets/auth/signin-background.png'
import {makeStyles} from '@mui/styles'
import { Typography, Grid, Hidden, Stack } from '@mui/material';
import InputForm from '../../../components/forms/InputForm';
import useSignInLayout from './useSignInLayout';
import ContainedButton from '../../../components/actions/ContainedButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import ShowPasswordBtn from '../../../components/actions/ShowPasswordBtn';
import TextButton from '../../../components/actions/TextButton';
import { Helmet } from 'react-helmet-async';
import LogoLarge from '../../../components/brandbook/LogoLarge';

const SignInLayout = () => {

    const classes = useStyles()

    const {formManager, loading, actions, error} = useSignInLayout()
    const {form, onChangeForm} = formManager

    const [password, setPassword] = useState(false)

    form.password.config = {...form.password.config, type: !password ? 'password' : 'text'}

    return ( 
        <div>
            <Helmet title='Autentificación | Velfare'></Helmet>
            <Grid container>
                <Grid item>
                    <div className={classes.form}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LogoLarge size={150} enableText/>
                            </Grid>
                            <Grid item={12}>
                                <div className={classes.header}>
                                    <Typography variant='h3'>
                                        Iniciar sesión
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.form_content}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <InputForm data={form.email} onChange={onChangeForm}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputForm data={form.password} onChange={onChangeForm} onKeyPress={actions.handleKeyPress}
                                            endAdornment={<ShowPasswordBtn
                                                show={password} 
                                                onChange={() => setPassword(!password)} />
                                            }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack alignItems='flex-end' >
                                                <TextButton onClick={actions.onRecoverPassword}>¿Olvidaste tu contraseña?</TextButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    
                                </div>
                                
                            </Grid>
                            {error ? <Grid item xs={12}>
                                <ErrorAlert 
                                error={error} 
                                onClose={actions.onClearError} />
                            </Grid> : null}
                            <Grid item xs={12}>
                                <ContainedButton 
                                loading={loading} 
                                fullWidth 
                                onClick={actions.onSubmit}
                                >Acceder</ContainedButton>
                            </Grid>
                            
                        </Grid>
                    </div>
                </Grid>
                <Hidden smDown>
                    <Grid item md>
                        <div className={classes.banner}>
                            <Image src={background} alt='' sx={{
                                width: '100%',
                                height: '100%',
                            }} />
                        </div>
                    </Grid>
                </Hidden>
                
               
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        overflow:'hidden',
    },
    form:{
        width: '100%',
        margin: 'auto',
        //display: 'flex',
        minHeight: '100vh',
        //justifyContent: 'center',
        padding: theme.spacing(6, 2),
        [theme.breakpoints.up('md')]: {
            //flexShrink: 0,
            width: 480,
            padding: theme.spacing(15, 8, 0, 8),
        },
        
    },
    banner:{
        width:'100%',
        height:'100vh',
        position:'relative'
    },
    header:{
        marginTop:32,
        width:'100%'
    },
    form_content:{
        margin:'40px 0px'
    }

}))
 
export default SignInLayout;