import React from 'react';
import logo from '../../assets/company/logo-text.png'
import {Stack} from '@mui/material'


const LogoLarge = ({
    size
}) => {
    return ( 
        <div>
            <Stack direction='row' spacing={1} alignItems='center'>
                <img src={logo} width={size} />
            </Stack>
            
        </div>
     );
}
 
export default LogoLarge;