import React from 'react'
import { Box, Dialog, Grid, Grow, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { red } from '@mui/material/colors';
import Iconify from '../iconify';

const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:32, 
        padding:'24px 24px',
        position:'relative',
        [theme.breakpoints.down('sm')]:{
            padding:theme.spacing(3),
            margin:8
        },
        
    },
    root:{

        //padding:40
    },
    icon:{
        color:'white',
        background:red[700],
        borderRadius:'50%',
        fontSize:54,
        padding:8
    },
    message:{
        paddingTop:16,
        paddingBottom:16,
        maxWidth:300
    },
    close_button:{
        //position:'absolute', right:16, top:16
    }
}))

const BaseModal = (props) => {

    const classes = useStyles()
    const {open, children, onClose, maxWidth, fullWidth, title, fullScreen, onCloseBtn} = props

    //if(!open) return null

    return(
        <Dialog open={!!open} maxWidth={maxWidth ? maxWidth : 'xs'} fullWidth={fullWidth !== undefined ? fullWidth : true} classes={{ paper: classes.paper }} className={classes.root}
         onClose={onClose} scroll='body' fullScreen={fullScreen}
         TransitionComponent={Transition}
         >
            <div className={classes.root}>
                <Box sx={{
                    paddingBottom:2
                }}>
                    <Grid container wrap='nowrap'>
                        <Grid item xs>
                            <Typography variant='h5'>{title}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton className={classes.close_button} onClick={onClose || onCloseBtn} ><Iconify icon='eva:close-fill' sx={{
                                width:24,
                                height:24
                            }} /></IconButton>
                        </Grid>
                    </Grid>
                </Box>
                {children}
            </div>
        </Dialog>
    )
}

export default BaseModal
