import React, { useCallback, useRef, useState } from 'react';
import { 
    Box, Button,
} from '@mui/material';

import PageLayout from '../../../layouts/PageLayout';
import Iconify from '../../../components/iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';
import NavigationTab from '../../../components/actions/NavigationTab';
import GeneralAnalyticsSection from './sections/GeneralAnalyticsSection';
import BusinessAnalyticsSection from './sections/BusinessAnalyticsSection';
import UsersAnalyticsSection from './sections/UsersAnalyticsSection';

const AnalyticsPage = () => {

    const [currentTab, setCurrentTab] = useState('general')
    const onChangeSection = useCallback((data) => setCurrentTab(data), [setCurrentTab])
    
    
    const TABS = [
        {
            value: 'general',
            label: 'General',
            icon: <Iconify icon="ic:round-dashboard" />,
            component: <GeneralAnalyticsSection />,
        },
        {
          value: 'site',
          label: 'Por sitio',
          icon: <Iconify icon="mdi:company" />,
          component: <BusinessAnalyticsSection />
        },
        {
            value: 'overview',
            label: 'Concentrado',
            icon: <Iconify icon="material-symbols:overview-key-outline-rounded" />,
            component: <UsersAnalyticsSection />
          },
    ];

    
 
    return ( 
        <PageLayout 
            title='Analíticos'
            title_seo='Analíticos | Velfare'
            //loading={system.loading}
            links={[
                {name:'Dashboard', href:PATH_DASHBOARD.general.app},
                {name:'Analíticos'},
            ]}
        >
            <div>
                <NavigationTab options={TABS} value={currentTab} onChange={onChangeSection}/>
                {TABS.map(
                (tab) =>
                    tab.value === currentTab && (
                        <Box key={tab.value} sx={{ mt: 5 }}>
                            {tab.component}
                        </Box>
                    )
                )}
            </div>
        </PageLayout>
     );
}
 
export default AnalyticsPage;