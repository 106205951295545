import { Typography, Stack, Alert } from '@mui/material';
import {makeStyles} from '@mui/styles'
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import PasswordIcon from '../../../assets/icons/PasswordIcon';
import ContainedButton from '../../../components/actions/ContainedButton';
import TextButton from '../../../components/actions/TextButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import Logo from '../../../components/brandbook/Logo';
import InputForm from '../../../components/forms/InputForm';
import Iconify from '../../../components/iconify';
import useAuth from '../../../hooks/api/useAuth';
import useForm from '../../../hooks/useForm';
import CompactContainer from '../../../layouts/CompactContainer';
import { PATH_AUTH } from '../../../routes/paths';

const ResetPasswordPage = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const controller = useForm(form_data)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const {form, onResetForm, onGetFormData} = controller
    const authcontroller = useAuth()

    const onChangeForm = (data) => {
        controller.onChangeForm(data)
        setError(null)
        setSuccess(null)
    }

    const onSubmit = async() => {
        //setError({message:'El correo ya existe'})
        

        const data2send = onGetFormData()
        setLoading(true)
        try {
            await authcontroller.actions.onRecoverPasswordByEmail(data2send)
            setSuccess('Correo enviado exitosamente')
            onResetForm()
        } catch (error) {
            console.log(error)
            setError(error)
        }
        
        setLoading(false)
    } 

    return ( 
        <CompactContainer>
            <Helmet title='Recuperación de contraseña | Velfare'/>
            <Box sx={{position:'fixed', top:16, left:16}}><Logo  size={48} /></Box>
            <div className={classes.root}>
                <Stack>
                    <div>
                        <PasswordIcon sx={{ mb: 5, height: 96 }} />
                    </div>
                    <Typography variant='h3' align='center'>¿Olvidaste tu contraseña?</Typography>
                    <Typography sx={{marginTop:2}} color='textSecondary'>
                        Por favor ingresa tu dirección de correo electrónico asociado a tu cuenta y te mandaremos un correo para que restablezcas tu contraseña
                    </Typography>
                    <Box sx={{marginTop:4, marginBottom:4}}>
                        <InputForm data={form.email} onChange={onChangeForm}/>
                    </Box>
                    {error && <Box sx={{marginTop:0, marginBottom:4}}>
                        <ErrorAlert
                        error={error} 
                        onClose={() => setError(null)} />
                    </Box>}
                    {success && <Box sx={{marginTop:0, marginBottom:4}}>
                        <Alert severity='success'  onClose={() => setSuccess(null)}>
                                {success}
                        </Alert>
                    </Box>}
                    <Box sx={{marginTop:0, marginBottom:4}}>
                        <ContainedButton fullWidth disabled={!form.email.isValid} loading={loading}
                        onClick={onSubmit}>Enviar solicitud</ContainedButton>
                    </Box>
                    <Box sx={{marginTop:0, marginBottom:4}}>
                        <TextButton fullWidth
                            onClick={() => navigate(PATH_AUTH.login)}
                        ><Iconify icon='eva:arrow-ios-back-fill'/> Regresar</TextButton>
                    </Box>
                </Stack>
            </div>
        </CompactContainer>
        
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        //border:'1px solid black'
    }
}))
 
export default ResetPasswordPage;

const form_data = {
    email: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label:'Email',
            placeholder:'Ingresa tu correo electrónico',
            helperText:'Correo no válido'
        },
        rules: {type: 'email'}
    },
}